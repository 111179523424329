import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import { Card, Stack, Container, Typography, TablePagination } from '@mui/material';

// api
import { getAllCreatorsApi, getSingleCreatorPostsApi } from '../api/dashboard';
// sections

import CreatorsList from '../sections/@dashboard/content/CreatorsList';
import SeePosts from '../sections/@dashboard/content/SeePosts';
import CreatorListToolbar from '../sections/@dashboard/creators/CreatorListToolbar';

// mock
import USERLIST from '../_mock/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CreatorsManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [creators, setCreators] = useState([]);
  const [singleCreatorPosts, setSingleCreatorPosts] = useState([]);

  const handleOpenMenu = (event, creatorId, isBanned) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === 'name' && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = creators.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - creators.length) : 0;

  const filteredUsers = creators ? applySortFilter(creators, getComparator(order, orderBy), filterName) : [];

  const isNotFound = !filteredUsers.length && !!filterName;

  const [DialogOpen, setDialogOpen] = useState(false);

  const [creatorId, setCreatorId] = useState('');
  const handleClickOpen = (id) => {
    setDialogOpen(true);
    setCreatorId(id);

    fetchSingleCreatorPosts(id);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  // function for fetch creators
  const fetchAllCreators = async (isBanned) => {
    const AdminToken = localStorage.getItem('admin');
    const creators = await getAllCreatorsApi(AdminToken, isBanned);
    setCreators(creators);
  };

  const fetchSingleCreatorPosts = async (creatorId) => {
    const AdminToken = localStorage.getItem('admin');
    const creatorPosts = await getSingleCreatorPostsApi(AdminToken, creatorId);
    setSingleCreatorPosts(creatorPosts);
  };

  useEffect(() => {
    fetchAllCreators();
  }, []);
  return (
    <>
      <Helmet>
        <title> Content | Luckyme Admin </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Content
          </Typography>
        </Stack>

        <Card>
          <CreatorListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onRequestSort={handleRequestSort}
            setOrder={setOrder}
            fetchAllCreators={fetchAllCreators}
          />

          <CreatorsList
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            creators={creators}
            filteredUsers={filteredUsers}
            selected={selected}
            filterName={filterName}
            SeeInformation="See Posts"
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleClick={handleClick}
            handleOpenMenu={handleOpenMenu}
            handleClickOpen={handleClickOpen}
            isNotFound={isNotFound}
            emptyRows={emptyRows}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={creators ? creators.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* ================see inforamtion Dailog==================== */}
      <SeePosts
        handleClose={handleClose}
        DialogOpen={DialogOpen}
        singleCreatorPosts={singleCreatorPosts}
        creatorId={creatorId}
        setSingleCreatorPosts={setSingleCreatorPosts}
        fetchSingleCreatorPosts={fetchSingleCreatorPosts}
      />
    </>
  );
}
