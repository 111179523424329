import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState,useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  Box,
  Alert,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// Api's
import { AddNewAdminApi,getAllAdminsApi,deleteAdminApi } from '../api/auth';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import AdminListToolbar from "../sections/@dashboard/admin/AdminListToolbar"
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },

  { id: 'role', label: 'Role', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// validation Sechema

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  isAdmin:Yup.boolean()
});

export default function CreatorsManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [alertMessage, setAlertMessage] = useState('');

  const [severity, setSeverity] = useState('');

  const [admins,setAdmins]=useState([]);
  const [adminId,setAdminId]=useState("");

  const handleAddNewAdmin = async (values) => {
    const Admin = await AddNewAdminApi(values);
    if (Admin?.data) {
      setAlertMessage('Admin Added Successfully');
      setSeverity('success');
      getAllAdmins();
     handleClose();
      setTimeout(() => {
        setAlertMessage('');
        setSeverity('');
      }, 1000);
    } else {
      const ErrorMsg = Admin?.errors[0]?.msg;
      setAlertMessage(ErrorMsg);
      setSeverity('error');
      setTimeout(() => {
        setAlertMessage('');
        setSeverity('');
      }, 1000);
    }
  };
  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAdmin:true
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleAddNewAdmin(values);
    },
  });

  const handleOpenMenu = (event,AdminId) => {
    setOpen(event.currentTarget);
    setAdminId(AdminId)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = admins.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - admins.length) : 0;

  const filteredUsers =admins ? applySortFilter(admins, getComparator(order, orderBy), filterName):[];

  const isNotFound = !filteredUsers.length && !!filterName;


  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const [DialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  // function for role drop down
  // const [Role, setRole] = useState('');
  // const [RoleOpen, setRoleOpen] = useState(false);

  // const handleChange = (event) => {
  //   setRole(event.target.value);
  // };

  // const handleRoleClose = () => {
  //   setRoleOpen(false);
  // };

  // const handleOpen = () => {
  //   setRoleOpen(true);
  // };

 const getAllAdmins=async()=>{
  const AdminToken=localStorage.getItem("admin");
  const adminData=await getAllAdminsApi(AdminToken)
  setAdmins(adminData)
 }

        // function to Delete the subscriber
        const DeleteAdmin = async (AdminId) => {
          const AdminToken = localStorage.getItem('admin');
          // eslint-disable-next-line
          const Admin = await deleteAdminApi(AdminToken, AdminId);
            const FilterdAdmin=admins.filter((user) => user._id!==AdminId)
            setAdmins(FilterdAdmin)
            setOpen(null);

        };

  useEffect(()=>{
    getAllAdmins()
  },[])
  return (
    <>
      <Helmet>
        <title> Creators | Luckyme Admin </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin Management
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
            Add New Admin
          </Button>
        </Stack>

        <Card>
          <AdminListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onRequestSort={handleRequestSort} setOrder={setOrder} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={admins ?admins.length :0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {admins && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, name} = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src="" />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">Admin</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event)=>handleOpenMenu(event,_id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={admins ? admins.length:0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit Rights
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={()=>DeleteAdmin(adminId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* ================see inforamtion Dailog==================== */}

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={DialogOpen} >
        <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleClose}>
          ADD NEW ADMIN
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ marginBottom: 1, mx: 3 }}>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
              <Avatar alt="Remy Sharp" src="" sx={{ width: 100, height: 100, bgColor: 'red' }} />
            </Box>
            {alertMessage && (
              <Alert variant="outlined" severity={severity} sx={{ mb: 1 }}>
                {alertMessage}
              </Alert>
            )}

            <TextField
              sx={{ my: 1 }}
              fullWidth
              label="User Name"
              name="name"
              autoComplete="off"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              fullWidth
              sx={{ my: 1 }}
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              margin="dense"
              name="password"
              type='password'
              sx={{ my: 1 }}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              fullWidth
            />
            <TextField
              margin="dense"
              name="confirmPassword"
              type='password'
              sx={{ my: 1 }}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Confirm Password"
              fullWidth
            />

      {/* <FormControl sx={{ mt: 2, width:'100%' }}>
        <InputLabel id="demo-controlled-open-select-label" >Role</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          fullWidth
          onClose={handleRoleClose}
          onOpen={handleOpen}
          name="Role"
          value={Role}
          label="Role"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Admin</MenuItem>
          <MenuItem value={20}>Editor</MenuItem>
          <MenuItem value={30}>Mananger</MenuItem>
        </Select>
      </FormControl> */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" size="large" sx={{ mx: 4, mb: 4 }} fullWidth>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
