import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Container,
  Typography,
  TablePagination,
  Box,
  Grid,
  Badge,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
// api
import {
  getAllCreatorsApi,
  getSingleCreatorApi,
  deleteCreatorApi,
  BanCreatorApi,
  getCreatorValidationRequestsApi,
  ValidateCreatorRequestsApi,
  RemoveCreatorApi,
} from '../api/dashboard';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import CreatorsList from '../sections/@dashboard/creators/CreatorsList';
import SeeInformation from '../sections/@dashboard/creators/SeeInformation';
import ValidateCreator from '../sections/@dashboard/creators/ValidateCreator';
import CreatorListToolbar from '../sections/@dashboard/creators/CreatorListToolbar';

// mock
import USERLIST from '../_mock/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CreatorsManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [creators, setCreators] = useState([]);
  const [singleCreator, setSingleCreator] = useState([]);
console.log("singleCreator",singleCreator)
  const [validateCreators, setValidateCreators] = useState([]);

  const [creatorId, setCreatorId] = useState('');

  const [creatorBanStatus, setCreatorBanStatus] = useState(false);

  const handleOpenMenu = (event, creatorId, isBanned) => {
    setOpen(event.currentTarget);
    setCreatorId(creatorId);

    if (isBanned) {
      setCreatorBanStatus(false);
    } else {
      setCreatorBanStatus(true);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === 'name' && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = creators.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - creators.length) : 0;

  const filteredUsers = creators ? applySortFilter(creators, getComparator(order, orderBy), filterName) : [];

  const isNotFound = !filteredUsers.length && !!filterName;

  const [DialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = (id) => {
    setDialogOpen(true);
    fetchSingleCreator(id);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  // Functions of Dialog for the validate creator submission
  const [CreatorOpen, setCreatorDialogOpen] = useState(false);

  const handleCreatorDialogOpen = () => {
    setCreatorDialogOpen(true);
    GetCreatorValidationRequests();
  };
  const handleCreatorClose = () => {
    setCreatorDialogOpen(false);
  };

  // function for fetch creators
  const fetchAllCreators = async (isBanned) => {
    const AdminToken = localStorage.getItem('admin');
    const creators = await getAllCreatorsApi(AdminToken, isBanned);
    setCreators(creators);
  };

  const GetCreatorValidationRequests = async () => {
    const AdminToken = localStorage.getItem('admin');
    const ValidateCreators = await getCreatorValidationRequestsApi(AdminToken);
    setValidateCreators(ValidateCreators);
  };

  const fetchSingleCreator = async (creatorId) => {
    const AdminToken = localStorage.getItem('admin');
    const creator = await getSingleCreatorApi(AdminToken, creatorId);
    setSingleCreator(creator);
  };

  // delete creator function
  const DeleteCreator = async (creatorId) => {
    const AdminToken = localStorage.getItem('admin');
    const creator = await deleteCreatorApi(AdminToken, creatorId);
    const FilterdCreator = creators.filter((user) => user._id !== creatorId);
    setCreators(FilterdCreator);
    setOpen(null);
  };

  // Ban Creator Function
  const BanCreator = async (creatorId, BanStatus) => {
    const AdminToken = localStorage.getItem('admin');
    const creatorBan = await BanCreatorApi(AdminToken, creatorId, BanStatus);
    if (creatorBan?.message) {
      fetchAllCreators();
    }
    setOpen(null);
  };
  // Remove Creator Function
  const RemoveCreator = async (creatorId, creatorStatus) => {
    const AdminToken = localStorage.getItem('admin');
    const removeCreator = await RemoveCreatorApi(AdminToken, creatorId, creatorStatus);
    const FilterdCreator = creators.filter((user) => user._id !== creatorId);
    setCreators(FilterdCreator);
    setOpen(null);
  };

  // Validate Creator Request Function
  const ValidateCreatorRequests = async (creatorId, RequestStatus) => {
    const AdminToken = localStorage.getItem('admin');
    const FilterdCreator = validateCreators.filter((user) => user._id !== creatorId);
    setValidateCreators(FilterdCreator);
    const creatorRequest = await ValidateCreatorRequestsApi(AdminToken, creatorId, RequestStatus);
    if (creatorRequest?.message) {
      fetchAllCreators();
    }
  };

  useEffect(() => {
    fetchAllCreators();
    GetCreatorValidationRequests();
  }, []);
  return (
    <>
      <Helmet>
        <title> Creators | Luckyme Admin </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Creators
          </Typography>
          <Badge badgeContent={validateCreators ? validateCreators.length : 0} color="primary">
            <Button variant="contained" onClick={handleCreatorDialogOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              Validate Creator Submission
            </Button>
          </Badge>
        </Stack>

        <Card>
          <CreatorListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onRequestSort={handleRequestSort}
            setOrder={setOrder}
            fetchAllCreators={fetchAllCreators}
          />

          <CreatorsList
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            creators={creators}
            filteredUsers={filteredUsers}
            selected={selected}
            filterName={filterName}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleClick={handleClick}
            handleOpenMenu={handleOpenMenu}
            handleClickOpen={handleClickOpen}
            isNotFound={isNotFound}
            emptyRows={emptyRows}
            SeeInformation="See Information"
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={creators ? creators.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => BanCreator(creatorId, creatorBanStatus)}>
          <Iconify icon={'mdi:ban'} sx={{ mr: 2 }} />
          {creatorBanStatus ? 'Ban' : 'Active'}
        </MenuItem>
        <MenuItem sx={{ color: 'warning' }} onClick={() => RemoveCreator(creatorId, false)}>
          <Iconify icon={'material-symbols:person-remove-outline'} sx={{ mr: 2 }} />
          Remove
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteCreator(creatorId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {/* ================see inforamtion Dailog==================== */}
      <SeeInformation handleClose={handleClose} DialogOpen={DialogOpen} singleCreator={singleCreator} />

      {/* ================Validate creator submission Dialog==================== */}
      <ValidateCreator
        handleClickOpen={handleClickOpen}
        ValidateCreatorRequests={ValidateCreatorRequests}
        validateCreators={validateCreators}
        handleCreatorClose={handleCreatorClose}
        handleRequestSort={handleRequestSort}
        creators={creators}
        CreatorOpen={CreatorOpen}
        order={order}
        orderBy={orderBy}
        handleClick={handleClick}
        selected={selected}
      />
    </>
  );
}
