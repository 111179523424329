import React, { useState,useContext,useEffect} from 'react';
// @mui
import { Typography, IconButton, Grid, Box, Button, Avatar, TextField,  Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
// icons
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DownloadIcon from '@mui/icons-material/Download';

// Api
import { ReplyHelpRequestsApi} from "../../../api/dashboard"

import { AuthContext } from '../../../context/AuthContext';




const ReplyDialog = ({ RequestOpen, handleHelpRequestDialogClose,singleHelpRequest,messageId,getAllHelpRequests }) => {


const  {user}=useContext(AuthContext)

  const [message,setMessage]=useState("")
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  // Functions of Dialog for the see information

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }




  const handleAdminReply=async(messageId,message)=>{
    try {
        const data={
          admin:user._id,
          adminReply:message
         }
        const adminToken=localStorage.getItem("admin");
        const AdminReply=await ReplyHelpRequestsApi(adminToken,messageId,data)
        if (AdminReply) {
          setAlertMessage('Reply sent successfully');
          setSeverity('success');

          setTimeout(() => {
            setAlertMessage('');
            getAllHelpRequests();
           handleHelpRequestDialogClose()
            setSeverity('');
            setMessage("")
          }, 1000);
        } else {
          setAlertMessage("Can't Reply at the moment");
          setSeverity('error');
          setTimeout(() => {
            setAlertMessage('');
            setSeverity('');
          }, 1000);
        }

    } catch (error) {
       console.log(error)
    }
  }
useEffect(()=>{
  setMessage("")
},[messageId])

  return (
    <div>

      <Dialog
        onClose={handleHelpRequestDialogClose}
        aria-labelledby="customized-dialog-title"
        open={RequestOpen}
        fullWidth
        maxWidth={"sm"}

        PaperProps={{
          style: {
            backgroundColor: '#F9FAFB',
            boxShadow: 'none',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleHelpRequestDialogClose}>
          Reply
        </BootstrapDialogTitle>
        <DialogContent dividers>
        {alertMessage && (
              <Alert variant="outlined" severity={severity} sx={{ mb: 1 }}>
                {alertMessage}
              </Alert>
            )}

          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', mt: 0, minHeight: 500 }}>
            <Box
              component="div" sx={{width:"100%"}}

            >
              <Grid container spacing={2} padding={4} >
                <Grid item sx={{ width: '100%', display: 'flex' ,}}>
                  <Avatar src="" sx={{ width: 60, height: 60 }} />
                  <Grid sx={{ ml: 1, mt: 2 }}>
                    <Typography variant="subtitle2" sx={{ lineHeight: 0.5 }}>
                      {singleHelpRequest && singleHelpRequest[0]?.user?.name}
                    </Typography>
                    <Typography variant="caption">{singleHelpRequest && singleHelpRequest[0]?.user?.email}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  { singleHelpRequest && singleHelpRequest[0]?.message}
                </Grid>
              </Grid>
              <Box sx={{px:4}}>
                <Typography variant="h6">Reply</Typography>
                <TextField  sx={{backgroundColor:"white",mb:2}} onChange={(e)=>setMessage(e.target.value)}  placeholder='Reply to message...' fullWidth multiline rows={9} />
              <Button variant="contained" size="large" disabled={message===""} onClick={()=>handleAdminReply(messageId,message)}>Reply</Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ReplyDialog.propTypes = {
  RequestOpen: PropTypes.bool,
  messageId: PropTypes.string,
  singleHelpRequest: PropTypes.array,
  getAllHelpRequests: PropTypes.func,
};

export default ReplyDialog;
