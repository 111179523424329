import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Home',
  //   path: '/dashboard/app',
  //   icon: <DashboardIcon/>,

  // },
  {
    title: 'Subscribers Management',
    path: '/dashboard/subscriberManagement',
    icon: <GroupsIcon/>,
  },
  {
    title: 'Creators Management',
    path: '/dashboard/creatorManagement',
    icon: <PeopleIcon/>,
  },
  {
    title: 'Content Management',
    path: '/dashboard/contentManagement',
    icon: <VideoLibraryIcon/>,
  },
  {
    title: 'Finance Management',
    path: '/dashboard/financeManagement',
    icon: <MonetizationOnIcon/>,
  },
  {
    title: 'Payment Management',
    path: '/dashboard/paymentManagement',
    icon: <CreditCardIcon/>,
  },
  {
    title: 'Support Center',
    path: '/dashboard/supportCenter',
    icon: <HelpOutlineIcon/>,
  },
  {
    title: 'Admin Management',
    path: '/dashboard/adminManagement',
    icon: <ManageAccountsIcon/>,
  },

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
