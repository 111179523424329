import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Divider,
  Box,
  Grid,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

// icons
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

// api
import { getAllSubscribersApi, getSingleSubscriberApi, deleteSubscriberApi, BanSubscriberApi } from '../api/dashboard';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// sections
import { UserListHead} from '../sections/@dashboard/user';
import SubscriberListToolbar from '../sections/@dashboard/subscribers/SubscriberListToolbar';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'label', label: '', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SubscriberManagement() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [subscribers, setSubscribers] = useState([]);
  const [singleSubscriber, setsingleSubscriber] = useState([]);

  const [subscriberId, setSubscribersId] = useState('');

  const [subscriberBanStatus, setSubscribersBanStatus] = useState(false);


  const handleOpenMenu = (event, CreatorId, isBanned) => {
    setOpen(event.currentTarget);
    setSubscribersId(CreatorId);
    if (isBanned) {
      setSubscribersBanStatus(false);
    } else {
      setSubscribersBanStatus(true);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subscribers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscribers.length) : 0;

  const filteredUsers = subscribers ? applySortFilter(subscribers, getComparator(order, orderBy), filterName):[];

  const isNotFound = !filteredUsers.length && !!filterName;

  // Functions of Dialog for the see information
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const [DialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = (id) => {
    setDialogOpen(true);
    fetchSingleSubscriber(id);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  // Function to Fetch Subscribers

  const getAllSubscribers = async (isCreator,isBanned) => {
     const AdminToken = localStorage.getItem('admin');
    const SubscribersData = await getAllSubscribersApi(AdminToken,isCreator,isBanned);
    setSubscribers(SubscribersData);
  };


  const fetchSingleSubscriber = async (subscriberId) => {
    const AdminToken = localStorage.getItem('admin');
    const subscriber = await getSingleSubscriberApi(AdminToken, subscriberId);
    setsingleSubscriber(subscriber);
  };

  // function to Delete the subscriber
  const DeleteSubscriber = async (subscriberId) => {
    const AdminToken = localStorage.getItem('admin');
    const subscriber = await deleteSubscriberApi(AdminToken, subscriberId);
    const FilterdSubsribers = subscribers.filter((user) => user._id !== subscriberId);
    setSubscribers(FilterdSubsribers);
    setOpen(null);
  };

  // Ban Subscriber Function
  const BanSubscriber = async (subscriberId, BanStatus) => {
    const AdminToken = localStorage.getItem('admin');
    const subscriberBan = await BanSubscriberApi(AdminToken, subscriberId, BanStatus);
    if (subscriberBan?.message) {
      getAllSubscribers();
    }
    setOpen(null);
  };

  useEffect(() => {
    getAllSubscribers();
  }, []);


  return (
    <>
      <Helmet>
        <title> Subscriber | Luckyme Admin </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subscribers
          </Typography>

        </Stack>

        <Card>
          <SubscriberListToolbar numSelected={selected.length}
           filterName={filterName}
           onFilterName={handleFilterByName}
           onRequestSort={handleRequestSort}
           subscribers={subscribers}
           setSubscribers={setSubscribers}
           getAllSubscribers={getAllSubscribers}
           setOrder={setOrder}

           />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={subscribers ? subscribers.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {subscribers && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, name, isCreator, isBanned } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src="" />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{isCreator ? 'Creator' : 'Subscriber'}</TableCell>

                        <TableCell align="left">{isCreator ? 'Yes' : 'No'}</TableCell>

                        <TableCell align="left">
                          <Label color={isBanned ? 'error' : 'success'}>{isBanned ? 'Banned' : 'Active'}</Label>
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleClickOpen(_id)}
                            color="inherit"
                            sx={{ backgroundColor: '' }}
                          >
                            See Information
                          </Button>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, _id, isBanned)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={subscribers ? subscribers.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => BanSubscriber(subscriberId, subscriberBanStatus)}>
          <Iconify icon={'mdi:ban'} sx={{ mr: 2 }} />
          {subscriberBanStatus ? 'Ban' : 'Active'}
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteSubscriber(subscriberId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {/* ================see inforamtion Dailog==================== */}
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={DialogOpen}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: '#F9FAFB',
              boxShadow: 'none',
            },
          }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleClose}>
            See Inforamtion
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Box component="div" sx={{ display: 'flex', mt: 4 }}>
              <Box
                component="div"
                sx={{ boxShadow: 3, p: 2, width: '60%', mr: 4, borderRadius: 2, backgroundColor: 'white' }}
              >
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    width: 100,
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    boxShadow: 4,
                    position: 'relative',
                    bottom: 40,
                  }}
                >
                  <PersonIcon sx={{ color: 'white', fontSize: 36 }} />
                </Box>
                <Typography variant="h5" gutterBottom>
                  Account Info
                </Typography>
                <Box component="div">
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Name:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.name}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Email:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.email}</Typography>
                    </Grid>

                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Stripe CustomerId:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.stripeCustomerId}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Followers:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.followers.length}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Subscribers:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.subscribers.length}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Subscribe To:</Typography>
                      <Typography sx={{ mx: 1 }}>{singleSubscriber[0]?.subscribedTo.length}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box component="div" sx={{ boxShadow: 3, p: 2, width: '40%', borderRadius: 2, backgroundColor: 'white' }}>
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    width: 100,
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    boxShadow: 4,
                    position: 'relative',
                    bottom: 40,
                  }}
                >
                  <QueryStatsIcon sx={{ color: 'white', fontSize: 36 }} />
                </Box>
                <Typography variant="h5" gutterBottom>
                  Account Stats
                </Typography>

                <Box>
                  <Grid container sx={{ display: 'flex' }}>
                    <Grid item sx={{ width: 250, height: 140, boxShadow: 4, mr: 2, p: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', pb: 1 }}>
                        <Typography>Subscribers</Typography>
                        <Typography variant="subtitle1" sx={{ color: 'primary.main', fontSize: 28 }}>
                          {singleSubscriber[0]?.subscribers.length}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: 'success.main', mr: 1 }}>
                          +30%
                        </Typography>
                        <Typography>Than the last Week</Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ width: 250, height: 140, boxShadow: 4, mr: 3, p: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', pb: 1 }}>
                        <Typography>Followers</Typography>
                        <Typography variant="subtitle1" sx={{ color: 'primary.main', fontSize: 28 }}>
                          {singleSubscriber[0]?.followers.length}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: 'error.main', mr: 1 }}>
                          -2%
                        </Typography>
                        <Typography>Than the last Week</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <Box
              component="div"
              sx={{ boxShadow: 5, p: 2, width: '100%', mt: 6, borderRadius: 2, backgroundColor: 'white' }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  width: 100,
                  height: 70,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 2,
                  boxShadow: 4,
                  position: 'relative',
                  bottom: 40,
                }}
              >
                <InsightsIcon sx={{ color: 'white', fontSize: 36 }} />
              </Box>
              <Typography variant="h5" gutterBottom>
                Activity historique
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
