import { useContext, useState, createContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { getCurrentAdminApi } from '../api/auth';


const token = localStorage.getItem('admin');
if(token){
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp < Date.now() / 1000) {
    localStorage.removeItem('admin');
  }
}




// 1-creating a Context
const AuthContext = createContext();

// 2-creating a provider
const AuthProvider = (props) => {
  const [user, setUser] = useState({});

  const AdminToken = localStorage.getItem('admin');

  const getCurrentAdmin = async () => {
    const currentAdmin = await getCurrentAdminApi(AdminToken);
    setUser(currentAdmin);
  };

  useEffect(() => {
    if (AdminToken) {
      getCurrentAdmin();
    }
  }, []);

  return <AuthContext.Provider value={{ user, setUser }}>{props.children}</AuthContext.Provider>;
};

// 3-creating a useContext hook for getting state
// custom hooks
const useProductContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider, AuthContext, useProductContext };
