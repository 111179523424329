import { getBaseUrl } from '../config/dev_env_config';

// =====================ADmin Login=====================
export const AdminLogin = (data) => {
  return fetch(`${getBaseUrl()}/api/admin/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

// =====================Api for Add New Admins/ signup====================

export const AddNewAdminApi = (data) => {
  return fetch(`${getBaseUrl()}/api/admin/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

// =====================fetch All Admins=====================
export const getAllAdminsApi = (adminToken) => {
  return fetch(`${getBaseUrl()}/api/admin/getAdmins`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

// =====================Delete Admins=====================
export const deleteAdminApi = (adminToken, AdminId) => {
  return fetch(`${getBaseUrl()}/api/admin/deleteAdmin/${AdminId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};


// =============================get me ============================

export const getCurrentAdminApi = (adminToken) => {
  return fetch(`${getBaseUrl()}/api/admin/me`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};