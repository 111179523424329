import { getBaseUrl } from '../config/dev_env_config';

// =====================fetch All Creators=====================
export const getAllCreatorsApi = (adminToken,isBanned) =>
  fetch(`${getBaseUrl()}/api/dashboard/getCreators?isBanned=${isBanned}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) =>  data )
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================fetch All Subscribers=====================
export const getAllSubscribersApi = (adminToken,isCreator,isBanned) =>
   fetch(`${getBaseUrl()}/api/dashboard/getSubscribers?isCreator=${isCreator}&isBanned=${isBanned}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================fetch Single Creators=====================
export const getSingleCreatorApi = (adminToken, CreatorId) =>
  fetch(`${getBaseUrl()}/api/dashboard/getSingleCreator/${CreatorId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================fetch Single subscriber=====================
export const getSingleSubscriberApi = (adminToken, SubscriberId) =>
   fetch(`${getBaseUrl()}/api/dashboard/getSingleCreator/${SubscriberId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================Delete Single subscriber=====================
export const deleteSubscriberApi = (adminToken, SubscriberId) =>
  fetch(`${getBaseUrl()}/api/dashboard/deleteSubscriber/${SubscriberId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================Delete Single Creator=====================
export const deleteCreatorApi = (adminToken, creatorId) =>
  fetch(`${getBaseUrl()}/api/dashboard/deleteCreator/${creatorId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


    // =====================Delete Single Creator=====================
export const RemoveCreatorApi = (adminToken, creatorId,data) =>
fetch(`${getBaseUrl()}/api/dashboard/removeCreator/${creatorId}`, {
  method: 'put',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${adminToken}`,
  },
  body: JSON.stringify({ isCreator: data }),
})
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Network response was not ok.');
  })
  .then((data) => data)
  .catch((error) => {
    console.error('Error:', error);
  });


// =====================Ban / unBan Subscriber=====================
export const BanSubscriberApi = (adminToken, SubscriberId, data) =>
   fetch(`${getBaseUrl()}/api/dashboard/banSubscriber/${SubscriberId}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
    body: JSON.stringify({ isBanned: data }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


// =====================Ban / unBan Subscriber=====================
export const BanCreatorApi = (adminToken, CreatorId, data) =>
   fetch(`${getBaseUrl()}/api/dashboard/banSubscriber/${CreatorId}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
    body: JSON.stringify({ isBanned: data }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });



    // =====================Featured /unFeatued Post=====================
export const FeaturedPostApi = (adminToken, CreatorId, data) =>
fetch(`${getBaseUrl()}/api/dashboard/featurePost/${CreatorId}`, {
 method: 'put',
 headers: {
   'Content-Type': 'application/json',
   Authorization: `Bearer ${adminToken}`,
 },
 body: JSON.stringify({ isFeatured: data }),
})
 .then((response) => {
   if (response.ok) {
     return response.json();
   }
   throw new Error('Network response was not ok.');
 })
 .then((data) => data)
 .catch((error) => {
   console.error('Error:', error);
 });



// =====================Get All Reported Content=====================
export const getReportedPostsApi = (adminToken,sortBy) =>
  fetch(`${getBaseUrl()}/api/dashboard/reportedPosts?sortBy=${sortBy}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const getSinglePostApi = (adminToken, postId) =>
   fetch(`${getBaseUrl()}/api/dashboard/singlePost/${postId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const deletePostApi = (adminToken, postId) =>
   fetch(`${getBaseUrl()}/api/dashboard/deletePost/${postId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const getCreatorValidationRequestsApi = (adminToken) =>
   fetch(`${getBaseUrl()}/api/dashboard/getCreatorValidationRequests`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const ValidateCreatorRequestsApi = (adminToken, creatorId, data) =>
   fetch(`${getBaseUrl()}/api/dashboard/ValidateCreatorRequests/${creatorId}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
    body: JSON.stringify({ isCreator: data }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const AddCreatorRequestsApi = (adminToken, data) =>
   fetch(`${getBaseUrl()}/api/dashboard/AddCreatorRequest`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const getAllHelpRequestsApi = (adminToken,sortBy) =>
   fetch(`${getBaseUrl()}/api/dashboard/geAllHelpMessages?sortBy=${sortBy}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const getSingleHelpRequestsApi = (adminToken, messageId) =>
  fetch(`${getBaseUrl()}/api/dashboard/geSingleHelpMessage/${messageId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });

export const ReplyHelpRequestsApi = (adminToken, messageId, data) =>
   fetch(`${getBaseUrl()}/api/dashboard/replyHelpMessages/${messageId}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


export const deleteHelpRequestsApi = (adminToken, messageId) =>
  fetch(`${getBaseUrl()}/api/dashboard/deleteHelpMessages/${messageId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${adminToken}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then((data) => data)
    .catch((error) => {
      console.error('Error:', error);
    });


    export const getSingleCreatorPostsApi = (adminToken, creatorId) =>
    fetch(`${getBaseUrl()}/api/dashboard/getSingleCreatorPosts/${creatorId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${adminToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((data) => data)
      .catch((error) => {
        console.error('Error:', error);
      });

