import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  roleSubscriber: sample([
    'Subscriber',

  ]),
  roleCreator: sample([
    'Creator',

  ]),
  roleAdmin: sample([
    'Admin',
    'Creator',
    'Editor',
    'Manger'

  ]),
  email:sample([
    'Altonhils@gmail.com',
    'user1@gmail.com',
    'userAmerb@gmail.com',

  ]),
  paymentMethod:sample([
    '...........244',
    '...........424',
    '...........958',
    '...........234',
    '...........974',
  ]),
  totalSpend:sample([
    '$1000',
    '$580',
    '$4894',
    '$500',
    '$9048'
  ]),
  payments:sample([
   '1',
   '3',
   '4',
   '5',
   '2'
  ]),
  refunds:sample([
    '$50',
    '$0',
    '$100',
    '$40',
  ]),
  created:sample([
    'March 4,2023',
    'April 10,2023',
    'january 9,2023',
    'May 14,2023',
  ]),
  message:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum voluptatum reiciendis, dicta voluptatibus, iure dignissimos quidem blanditiis excepturi illum, hic tempora quos nihil.'

}));

export default users;
