import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import SupportListToolbar from '../sections/@dashboard/support/SupportListToolbar';
import ReplyDialog from "../sections/@dashboard/support/ReplyDialog"

// Api
import { getAllHelpRequestsApi,getSingleHelpRequestsApi,deleteHelpRequestsApi } from '../api/dashboard';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'Message', label: 'Message Request', alignRight: false },
  { id: 'f',label:"" },
  { id: '',label:"" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index] );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SupportCenter() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [helpRequests, setHelpRequests] = useState([]);
console.log("help requests",helpRequests)
  const [singleHelpRequest,setSingleHelpRequest]=useState([])

  const [messageId,setMessageId]=useState("");

  const [RequestUsers,setRequestUsers]=useState([])

  const handleOpenMenu = (event,messageId) => {
    setOpen(event.currentTarget);
    setMessageId(messageId)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = helpRequests.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers =helpRequests ? applySortFilter(helpRequests, getComparator(order, orderBy), filterName):[];

  const isNotFound = !filteredUsers.length && !!filterName;

    // Function for dialog of Reply helpRequest

    const [RequestOpen, setRequestDialogOpen] = useState(false);

    const handleHelpRequestDialogOpen = (messageId) => {
      setRequestDialogOpen(true);
      setMessageId(messageId)
      getSingleHelpRequests(messageId)
    };
    const handleHelpRequestDialogClose = () => {
      setRequestDialogOpen(false);
    };
    // function for fetch helpRequests
    const getAllHelpRequests= async (sortBy) => {
      const AdminToken = localStorage.getItem('admin');
      const helpRequests = await getAllHelpRequestsApi(AdminToken,sortBy);
      setHelpRequests(helpRequests);
      const users = helpRequests.map(a => a.user);
      setRequestUsers(users)
    };


    const getSingleHelpRequests= async (messageId) => {
      const AdminToken = localStorage.getItem('admin');
      const helpRequests = await getSingleHelpRequestsApi(AdminToken,messageId);
      setSingleHelpRequest(helpRequests);
    };

      // function to Delete the help message
      const DeleteHelpMessage = async (messageId) => {
        const AdminToken = localStorage.getItem('admin');
        const Message = await deleteHelpRequestsApi(AdminToken, messageId);
          const FilteredMessage=helpRequests.filter((message) => message._id!==messageId)
          setHelpRequests(FilteredMessage)
          setOpen(null);
      };

    useEffect(()=>{
      getAllHelpRequests();
    },[])
  return (
    <>
      <Helmet>
        <title> Support | Luckyme Admin </title>
      </Helmet>

      <Container>
        <Stack mb={5}>
          <Typography variant="h4" gutterBottom>
            Support Center
          </Typography>

        </Stack>

        <Card>
          <SupportListToolbar
          numSelected={selected.length}
           filterName={filterName}
           onFilterName={handleFilterByName}
           getAllHelpRequests={getAllHelpRequests}
           />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={helpRequests ? helpRequests.length : 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {helpRequests && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, name,user, message } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={user.name} src="" />
                            <Typography variant="subtitle2" noWrap>
                              {user.name}

                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{message}</TableCell>
                        <TableCell align="left">
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleHelpRequestDialogOpen(_id)}
                            color="inherit"
                            sx={{ backgroundColor: '' }}
                          >
                            Reply
                          </Button>
                        </TableCell>


                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event)=>handleOpenMenu(event,_id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>



          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={helpRequests ? helpRequests.length :0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        <MenuItem sx={{ color: 'error.main' }} onClick={()=>DeleteHelpMessage(messageId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>


      {/* Dialog for Reply the help request */}
      <ReplyDialog
      RequestOpen={RequestOpen}
      handleHelpRequestDialogClose={handleHelpRequestDialogClose}
      getAllHelpRequests={getAllHelpRequests}
      singleHelpRequest={singleHelpRequest}
      messageId={messageId}
      />
    </>
  );
}
