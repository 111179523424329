import React from 'react';
// @mui
import { Typography, Divider, IconButton, Box, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
// icons
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';
import CloseIcon from '@mui/icons-material/Close';

const SeeInformation = ({ handleClose, singleCreator, DialogOpen }) => {
  //  Functions of Dialog for the see information

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const dateString = singleCreator[0]?.creatorDetails?.dob;
  const cleanedDateString = dateString?.replace('Z', '');
  const dob = new Date(cleanedDateString);
  const dateOfBirth = dob?.toLocaleDateString();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={DialogOpen}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: '#F9FAFB',
          boxShadow: 'none',
        },
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleClose}>
        See Information
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box component="div" sx={{ display: 'flex', mt: 4 }}>
          <Box
            component="div"
            sx={{ boxShadow: 3, p: 2, width: '60%', mr: 2, borderRadius: 2, backgroundColor: 'white' }}
          >
            <Box
              sx={{
                bgcolor: 'primary.main',
                width: 100,
                height: 70,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
                boxShadow: 4,
                position: 'relative',
                bottom: 40,
              }}
            >
              <PersonIcon sx={{ color: 'white', fontSize: 36 }} />
            </Box>
            <Typography variant="h5" gutterBottom>
              Account Info
            </Typography>
            <Box component="div">
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Name:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.name}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Email:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.email}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Address:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.creatorDetails?.address}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">City:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.creatorDetails?.city}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Province:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.creatorDetails?.province}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Country:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.creatorDetails?.country}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">DOB:</Typography>
                  <Typography sx={{ mx: 1 }}>{dateOfBirth}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Postal Code:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.creatorDetails?.postalCode}</Typography>
                </Grid>

                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Stripe CustomerId:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.stripeCustomerId}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Followers:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.followers?.length}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Subscribers:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.subscribers?.length}</Typography>
                </Grid>
                <Grid item xs={6} display="flex">
                  <Typography variant="subtitle1">Subscribed To:</Typography>
                  <Typography sx={{ mx: 1 }}>{singleCreator[0]?.subscribedTo?.length}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Picture of ID Card:</Typography>
                  <img
                    style={{ borderRadius: '8px', padding: '18px' }}
                    alt="id card"
                    src={`${singleCreator[0]?.creatorDetails?.documents?.idCardPicture}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Picture of holding ID Card:</Typography>
                  <img
                    style={{ borderRadius: '8px', padding: '18px' }}
                    alt="id card"
                    src={`${singleCreator[0]?.creatorDetails?.documents?.holdingIdCardPicture}`}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box component="div" sx={{ boxShadow: 3, p: 2, width: '40%', borderRadius: 2, backgroundColor: 'white' }}>
            <Box
              sx={{
                bgcolor: 'primary.main',
                width: 100,
                height: 70,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 2,
                boxShadow: 4,
                position: 'relative',
                bottom: 40,
              }}
            >
              <QueryStatsIcon sx={{ color: 'white', fontSize: 36 }} />
            </Box>
            <Typography variant="h5" gutterBottom>
              Account Stats
            </Typography>

            <Box>
              <Grid container sx={{ display: 'flex' }}>
                <Grid item sx={{ width: 250, height: 140, boxShadow: 4, mr: 2, p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', pb: 1 }}>
                    <Typography>Subscribers</Typography>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main', fontSize: 28 }}>
                      {singleCreator[0]?.subscribers.length}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'success.main', mr: 1 }}>
                      +30%
                    </Typography>
                    <Typography>Than the last Week</Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ width: 250, height: 140, boxShadow: 4, mr: 3, p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', pb: 1 }}>
                    <Typography>Followers</Typography>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main', fontSize: 28 }}>
                      {singleCreator[0]?.followers.length}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'error.main', mr: 1 }}>
                      -2%
                    </Typography>
                    <Typography>Than the last Week</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box
          component="div"
          sx={{ boxShadow: 5, p: 2, width: '100%', mt: 6, borderRadius: 2, backgroundColor: 'white' }}
        >
          <Box
            sx={{
              bgcolor: 'primary.main',
              width: 100,
              height: 70,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 2,
              boxShadow: 4,
              position: 'relative',
              bottom: 40,
            }}
          >
            <InsightsIcon sx={{ color: 'white', fontSize: 36 }} />
          </Box>
          <Typography variant="h5" gutterBottom>
            Activity historique
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

SeeInformation.propTypes = {
  handleClose: PropTypes.func,
  DialogOpen: PropTypes.bool,
  singleCreator: PropTypes.array,
};
export default SeeInformation;
