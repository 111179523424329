import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
} from '@mui/material';

// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import { UserListHead } from '../user';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'lablel', label: '', alignRight: false },
  { id: '' },
];

const CreatorsList = ({
  order,
  page,
  orderBy,
  creators,
  selected,
  rowsPerPage,
  emptyRows,
  handleRequestSort,
  handleSelectAllClick,
  handleClick,
  handleClickOpen,
  handleOpenMenu,
  filteredUsers,
  isNotFound,
  filterName,
  SeeInformation
}) => {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={creators ? creators.length : 0}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const { _id, name, isCreator, isBanned } = row;
              const selectedUser = selected.indexOf(_id) !== -1;

              return (
                <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar alt={name} src="" />
                      <Typography variant="subtitle2" noWrap>
                        {name}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">Creator</TableCell>

                  <TableCell align="left">{isCreator ? 'Yes' : 'No'}</TableCell>

                  <TableCell align="left">
                    <Label color={isBanned ? 'error' : 'success'}>{isBanned ? 'Banned' : 'Active'}</Label>
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => handleClickOpen(_id)}
                      color="inherit"
                      sx={{ backgroundColor: '' }}
                    >
                      {SeeInformation}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, _id, isBanned)}>
                      <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>

          {isNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{filterName}&quot;</strong>.
                      <br /> Try checking for typos or using complete words.
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

CreatorsList.propTypes = {
  handleOpenMenu: PropTypes.func.isRequired,
  page: PropTypes.number,
  filteredUsers: PropTypes.array,
  creators: PropTypes.array,
  selected: PropTypes.array,
  filterName: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  emptyRows: PropTypes.number,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  rowsPerPage: PropTypes.number,
  isNotFound: PropTypes.bool,
};

export default CreatorsList;
