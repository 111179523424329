import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox,Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// components
import Iconify from '../../../components/iconify';

// APi

import { AdminLogin } from '../../../api/auth';
// context

import { AuthContext } from '../../../context/AuthContext';

// ----------------------------------------------------------------------

// validation Sechema

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});



export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage ,setErrorMessage]=useState("")
  const { user, setUser } = useContext(AuthContext);

  const  initialValues={
    email: '',
    password: '',
  }


  const handleSubmit = async (values) => {
    const isAdminLogin = await AdminLogin(values);
    setUser(isAdminLogin?.data?.user);
    const AdminToken = isAdminLogin?.data?.token;

    if (isAdminLogin?.data) {
      localStorage.setItem('admin', AdminToken);
      navigate('/dashboard', { replace: true });
    }else {
      const ErrorMsg=isAdminLogin?.errors[0]?.msg;


      setErrorMessage(ErrorMsg)
      setTimeout(()=>{
        setErrorMessage("");
      },1000)

    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },

  });


  return (
    <>
    { errorMessage && <Alert variant="outlined" severity="error" sx={{mb:2}}>
      Invalid Credenials
    </Alert>}
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        {/* <TextField name="email" label="Email address" onChange={handleChange} /> */}
        <TextField
        name="email"
        label="Email"
        autoComplete='off'
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
        <TextField
               name="password"
               label="Password"
               value={formik.values.password}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               error={formik.touched.password && Boolean(formik.errors.password)}
               helperText={formik.touched.password && formik.errors.password}
               type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" onClick={formik.handleSubmit} variant="contained" >
        Login
      </LoadingButton>
      </form>
    </>
  );
}
