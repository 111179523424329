import React,{useState} from 'react';
// @mui
import {
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Box,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
// icons
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

// components
import Scrollbar from '../../../components/scrollbar';

import RequestForm from './ReqestForm';

import { UserListHead } from '../user';

const VALIDATE_CREATORS_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: '', label: '', alignRight: false },
  { id: 'lablel', label: '', alignRight: false },
  { id: '' },
];

const ValidateCreator = ({
  handleClick,
  handleCreatorClose,
  handleRequestSort,
  handleSelectAllClick,
  order,
  orderBy,
  ValidateCreatorRequests,
  validateCreators,
  CreatorOpen,
  creators,
  selected,
  handleClickOpen,
}) => {
  // Functions of Dialog for the see information

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  // Function for dialog of AddRequest

  const [RequestOpen, setRequestDialogOpen] = useState(false);

  const handleCreatorRequestDialogOpen = () => {
    setRequestDialogOpen(true);

  };
  const handleCreatorRequestDialogClose = () => {
    setRequestDialogOpen(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleCreatorClose}
        aria-labelledby="customized-dialog-title"
        open={CreatorOpen}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: '#F9FAFB',
            boxShadow: 'none',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleCreatorClose}>
          Validate Creator Submission
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Box sx={{display:"flex",justifyContent:"end",mr:4}}>
            <Button variant='contained' onClick={()=>handleCreatorRequestDialogOpen()}>Add a Request</Button>
            </Box>
          <Box component="div" sx={{ display: 'flex', mt: 4 ,minHeight:500}}>
            <Box
              component="div"
              sx={{ boxShadow: 3, p: 2, width: '100%', mr: 4, borderRadius: 2, backgroundColor: 'white' }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  width: 100,
                  height: 70,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 2,
                  boxShadow: 4,
                  position: 'relative',
                  bottom: 40,
                }}
              >
                <PersonIcon sx={{ color: 'white', fontSize: 36 }} />
              </Box>
              <Typography variant="h3" color="primary" gutterBottom>
               Requests
              </Typography>
              <Box component="div">
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={VALIDATE_CREATORS_HEAD}
                        rowCount={creators ?  creators.length: 0}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {validateCreators
                          ? validateCreators.map((row) => {
                              const { name, _id } = row;
                              const selectedUser = selected.indexOf(name) !== -1;
                              return (
                                <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                  <TableCell padding="checkbox">
                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                  </TableCell>

                                  <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Avatar alt={name} src="" />
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => handleClickOpen(_id)}
                                      color="inherit"
                                      sx={{ backgroundColor: '' }}
                                    >
                                      See Information
                                    </Button>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() => ValidateCreatorRequests(_id, true)}
                                      startIcon={<CheckCircleIcon />}
                                      color="success"
                                      sx={{ backgroundColor: '' }}
                                    >
                                      Approve
                                    </Button>
                                  </TableCell>

                                  <TableCell align="left">
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() => ValidateCreatorRequests(_id, false)}
                                      startIcon={<CloseIcon />}
                                      color="error"
                                      sx={{ backgroundColor: '' }}
                                    >
                                      Reject
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : ''}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                { (validateCreators && validateCreators.length === 0)  ? (
                  <Typography sx={{ textAlign: 'center', p: 4 }}>No Request Found!</Typography>
                ) : (
                  ''
                )}
              </Box>
            </Box>


          </Box>
        </DialogContent>
      </Dialog>

{/* ADD Request From */}
      <RequestForm
      RequestOpen={RequestOpen}
      handleCreatorRequestDialogClose={handleCreatorRequestDialogClose}
      />
    </div>
  );
};

ValidateCreator.propTypes = {
  creators: PropTypes.array,
  selected: PropTypes.array,
  CreatorOpen: PropTypes.bool,
  handleClickOpen: PropTypes.func,
  orderBy: PropTypes.string,
  order: PropTypes.string,
  validateCreators: PropTypes.array,
  handleClick: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleCreatorClose: PropTypes.func,
  ValidateCreatorRequests: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
};

export default ValidateCreator;
