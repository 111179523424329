import React, { useState } from 'react';
import { filter } from 'lodash';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import PropTypes from 'prop-types';
// icons

import CloseIcon from '@mui/icons-material/Close';

// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// sections
import { UserListHead } from '../user';
import PostDetails from './PostDetails';
// Apis
import { getSinglePostApi, deletePostApi, FeaturedPostApi } from '../../../api/dashboard';

// ----------------------------------------------------------------------
import USERLIST from '../../../_mock/user';

const TABLE_HEAD = [
  { id: 'Post', label: 'Post', alignRight: false },
  { id: 'report', label: 'Report', alignRight: false },
  { id: 'featured', label: 'Featured', alignRight: false },
  { id: 'info' },
  { id: 'btn' },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.description.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
// eslint-disable-next-line
const SeePosts = ({
  handleClose,
  singleCreatorPosts,
  DialogOpen,
  fetchSingleCreatorPosts,
  creatorId,
  setSingleCreatorPosts,
}) => {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [singlePost, setSinglePost] = useState([]);

  const [PostDialogOpen, setPostDialogOpen] = useState(false);

  const [postId, setPostId] = useState('');

  const [featuredStatus, setFeaturedStatus] = useState(false);
  const handleOpenMenu = (event, postId, FeaturedStatus) => {
    setOpen(event.currentTarget);
    setPostId(postId);

    if (FeaturedStatus) {
      setFeaturedStatus(false);
    } else {
      setFeaturedStatus(true);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
    fetchSingleCreatorPosts(creatorId);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // eslint-disable-next-line
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = singleCreatorPosts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // eslint-disable-next-line
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - singleCreatorPosts.length) : 0;

  const filteredContent = singleCreatorPosts
    ? applySortFilter(singleCreatorPosts, getComparator(order, orderBy), filterName)
    : [];
  //  const filteredContent=[]
  const isNotFound = !filteredContent.length && !!filterName;

  // Functions of Dialog for the see information
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handlePostDialogClose = () => {
    setPostDialogOpen(false);
  };
  const handlePostDialogOpen = (postId) => {
    setPostDialogOpen(true);
    getSinglePost(postId);
  };

  const getSinglePost = async (postId) => {
    const AdminToken = localStorage.getItem('admin');
    const post = await getSinglePostApi(AdminToken, postId);
    setSinglePost(post);
  };

  const DeleteReportedPost = async (postId) => {
    const AdminToken = localStorage.getItem('admin');
    // eslint-disable-next-line
    const post = await deletePostApi(AdminToken, postId);
    const FilterdReports = singleCreatorPosts.filter((post) => post._id !== postId);
    setSingleCreatorPosts(FilterdReports);
    setOpen(null);
  };

  const FeaturedPost = async (postId, postStatus) => {
    const AdminToken = localStorage.getItem('admin');
    const FeaturedPostData = await FeaturedPostApi(AdminToken, postId, postStatus);
    if (FeaturedPostData) {
      fetchSingleCreatorPosts(creatorId);
      setOpen(null);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={DialogOpen}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: '#F9FAFB',
            boxShadow: 'none',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleClose}>
          See Posts
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box component="div" sx={{ display: 'flex', mt: 4 }}>
            <Box component="div" sx={{ p: 2, width: '100%', mr: 2, borderRadius: 2, backgroundColor: 'white' }}>
              <Card>
                <Typography variant="h3" color="primary" sx={{ textAlign: 'center', py: 2 }}>
                  POSTS
                </Typography>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {singleCreatorPosts &&
                          filteredContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { _id, description, featured, Reported } = row;
                            const selectedUser = selected.indexOf(_id) !== -1;

                            return (
                              <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                <TableCell padding="checkbox">
                                  <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                                </TableCell>

                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography variant="subtitle2">{description.substring(0, 50)}...</Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  <Label color={Reported[0]?.isReported ? 'error' : 'success'}>
                                    {Reported[0]?.isReported ? ' Reported  ' : 'Not Reported'}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">
                                  <Label color={featured ? 'success' : 'default'}>
                                    {featured ? 'Featured' : 'Not Featured'}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => handlePostDialogOpen(_id)}
                                    color="inherit"
                                    sx={{ backgroundColor: '' }}
                                  >
                                    View Post Details
                                  </Button>
                                </TableCell>

                                <TableCell align="right">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(event) => handleOpenMenu(event, _id, featured)}
                                  >
                                    <Iconify icon={'eva:more-vertical-fill'} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                    {singleCreatorPosts?.length === 0 ? (
                      <Box sx={{ width: '100%', textAlign: 'center', py: 8 }}>
                        <Typography variant="h5" color="primary">
                          No Post Found!
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )}
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={singleCreatorPosts ? singleCreatorPosts.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>

              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteReportedPost(postId)}>
                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                  Delete
                </MenuItem>
                <MenuItem onClick={() => FeaturedPost(postId, featuredStatus)}>
                  <Iconify icon={'material-symbols:star-outline'} sx={{ mr: 2 }} />
                  {featuredStatus ? 'Feature' : 'UnFeature'}
                </MenuItem>
              </Popover>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <PostDetails
        handlePostDialogClose={handlePostDialogClose}
        PostDialogOpen={PostDialogOpen}
        singlePost={singlePost}
      />
    </>
  );
};

SeePosts.propTypes = {
  creatorId: PropTypes.string,
  DialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  singleCreatorPosts: PropTypes.array,
  fetchSingleCreatorPosts: PropTypes.func,
};
export default SeePosts;
