import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import SubscriberManagement from './pages/SubscriberManagement';
import CreatorsManagement from './pages/CreatorsManagement';
import ContentManagement from './pages/ContentManagement';
import FinanceManagement from './pages/FinanceManagement';
import PaymentManagement from './pages/PaymentManagement';
import SupportCenter from './pages/SupportCenter';
import AdminManagement from './pages/AdminManagement';

// ----------------------------------------------------------------------

export default function Router() {
  const AdminToken=localStorage.getItem("admin")

  const routes = useRoutes([

      {
      path: '/dashboard',
      element:AdminToken ? <DashboardLayout /> :
        <Navigate to="/admin/login" />
      ,
      children: [
        { element: <Navigate to="/dashboard/subscriberManagement" />, index: true },
        {path:"subscriberManagement",element:<SubscriberManagement/>},
        {path:"creatorManagement",element:<CreatorsManagement/>},
        {path:"contentManagement",element:<ContentManagement/>},
        {path:"financeManagement",element:<FinanceManagement/>},
        {path:"paymentManagement",element:<PaymentManagement/>},
        {path:"supportCenter",element:<SupportCenter/>},
        {path:"adminManagement",element:<AdminManagement/>}
      ],
    },
    {
      path: 'login',
      element:!AdminToken ? <LoginPage /> :<Navigate to="/dashboard/subscriberManagement" />,
    },
    {
      element: <SimpleLayout />,
      children: [
       { element: <Navigate to="/dashboard/subscriberManagement" />, index: true },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
