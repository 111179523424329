import React, { useState } from 'react';
// @mui
import {
  Typography,
  IconButton,
  Grid,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
// icons
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DownloadIcon from '@mui/icons-material/Download';

// Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Api
import { AddCreatorRequestsApi } from '../../../api/dashboard';
// validation Sechema

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email().required('Email is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  postalCode: Yup.string().required('Postal Code is required'),
  documentType: Yup.string().required('Please select document'),
  dob: Yup.date().required('DOB is required'),
  idExpiration: Yup.date().required('ID Expiration is required'),
  instagram: Yup.string(),
  twitter: Yup.string(),
  // idCardPicture: Yup.string().required('ID CARD PICTURE IS REQUIRED'),
  // holdingIdCardPicture: Yup.string().required('HOLDING ID CARD PICTURE IS REQUIRED'),
  // idCardPicture: Yup.mixed().required('ID CARD PICTURE IS REQUIRED'),
  // holdingIdCardPicture: Yup.mixed().required('HOLDING ID CARD PICTURE IS REQUIRED'),
});

const RequestForm = ({ RequestOpen, handleCreatorRequestDialogClose }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    documentType: '',
    dob: '',
    idExpiration: '',
    instagram: '',
    twitter: '',
    // idCardPicture:null,
    // holdingIdCardPicture:null,
    // idCardPicture:'',
    // holdingIdCardPicture:''
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleAddCreatorRequest(values);
    },
  });
  // Functions of Dialog for the see information

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  // eslint-disable-next-line
  const [ID, setID] = useState('');
  // eslint-disable-next-line
  const [IDOpen, setIDOpen] = useState(false);
  // eslint-disable-next-line
  const handleChange = (event) => {
    setID(event.target.value);
  };

  const handleIDClose = () => {
    setIDOpen(false);
  };
  const handleOpen = () => {
    setIDOpen(true);
  };

  const handleAddCreatorRequest = async (creator) => {
    const AdminToken = localStorage.getItem('admin');
    const AddCreator = await AddCreatorRequestsApi(AdminToken, creator);
    return AddCreator;
  };
  return (
    <div>
      <Dialog
        onClose={handleCreatorRequestDialogClose}
        aria-labelledby="customized-dialog-title"
        open={RequestOpen}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: '#F9FAFB',
            boxShadow: 'none',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handleCreatorRequestDialogClose}>
          Validate Creator Submission
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', mt: 4, minHeight: 500 }}>
            <Box
              component="div"
              sx={{ boxShadow: 3, p: 2, width: '80%', mr: 4, borderRadius: 2, backgroundColor: 'white' }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.main',
                  width: 100,
                  height: 70,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 2,
                  boxShadow: 4,
                  position: 'relative',
                  bottom: 40,
                }}
              >
                <PersonAddIcon sx={{ color: 'white', fontSize: 36 }} />
              </Box>
              <Typography variant="h3" color="primary" sx={{ textAlign: 'center' }} gutterBottom>
                ADD A Request
              </Typography>
              <Box component="div">
                <form onSubmit={formik.handleSubmit}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ my: 1 }}
                        fullWidth
                        label="First Name"
                        name="firstName"
                        autoComplete="off"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="lastName"
                        label="Last Name"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="address"
                        label="Address"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="country"
                        label="Country"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="city"
                        label="City"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="state"
                        label="State/Province"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="postalCode"
                        label="Postal Code"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="twitter"
                        label="Twitter (optional)"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.twitter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.twitter && Boolean(formik.errors.twitter)}
                        helperText={formik.touched.twitter && formik.errors.twitter}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="instagram"
                        label="Instagram (optional)"
                        fullWidth
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.instagram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                        helperText={formik.touched.instagram && formik.errors.instagram}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ mt: 2, width: '100%' }}>
                        <InputLabel id="demo-controlled-open-select-label">Document Type</InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          fullWidth
                          onClose={handleIDClose}
                          onOpen={handleOpen}
                          label="Document Type"
                          name="documentType"
                          value={formik.values.documentType}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.documentType && Boolean(formik.errors.documentType)}
                          helperText={formik.touched.documentType && formik.errors.documentType}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="idCard">ID Card</MenuItem>
                          <MenuItem value="passport">Passport</MenuItem>
                        </Select>
                        {formik.touched.documentType && formik.errors.documentType && (
                          <div style={{ color: 'red', fontSize: '13px', marginLeft: '5px' }}>
                            {formik.errors.documentType}
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        id="DOB"
                        label="DOB"
                        type="date"
                        name="dob"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ my: 1 }}
                        autoComplete="off"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                        helperText={formik.touched.dob && formik.errors.dob}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        type="date"
                        fullWidth
                        name="idExpiration"
                        label="ID Expiration Date"
                        sx={{ my: 1 }}
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={formik.values.idExpiration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.idExpiration && Boolean(formik.errors.idExpiration)}
                        helperText={formik.touched.idExpiration && formik.errors.idExpiration}
                      />
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems="center">
                      <Grid>
                        <Typography variant="h6">PHOTO OF YOUR ID</Typography>
                        <Typography variant="caption">
                          Please Upload the photo of your ID Document (i.e ID Card)
                        </Typography>
                        <img src="/admin/assets/images/idCard/idCard.png" alt="id" style={{ width: '300px' }} />
                      </Grid>
                      <Grid marginLeft={24}>
                        <Button
                          variant="default"
                          sx={{ color: 'primary.main' }}
                          size="large"
                          component="label"
                          startIcon={<DownloadIcon />}
                        >
                          Select File
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            name="idCardPicture"
                            onChange={(event) => {
                              formik.setFieldValue('idCardPicture', event.currentTarget.files[0]);
                            }}
                          />
                        </Button>
                        <Typography sx={{ mx: 2, color: 'black' }}>
                          {formik.values.idCardPicture && formik.values.idCardPicture.name}
                        </Typography>
                        {formik.errors.idCardPicture && formik.touched.idCardPicture && (
                          <div style={{ color: 'red' }}>{formik.errors.idCardPicture}</div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Grid>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          PHOTO OF HOLDING YOUR ID CARD
                        </Typography>
                        <img
                          src="/admin/assets/images/idCard/manHoldingId.jpg"
                          alt="id"
                          style={{ width: '300px', borderRadius: '12px' }}
                        />
                      </Grid>
                      <Grid marginLeft={24}>
                        <Button
                          variant="default"
                          sx={{ color: 'primary.main' }}
                          size="large"
                          component="label"
                          startIcon={<DownloadIcon />}
                        >
                          Select File
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            name="holdingIdCardPicture"
                            onChange={(event) => {
                              formik.setFieldValue('holdingIdCardPicture', event.currentTarget.files[0]);
                            }}
                          />
                        </Button>
                        <Typography sx={{ mx: 2, color: 'black' }}>
                          {formik.values.holdingIdCardPicture && formik.values.holdingIdCardPicture.name}
                        </Typography>
                        {formik.errors.holdingIdCardPicture && formik.touched.holdingIdCardPicture && (
                          <div style={{ color: 'red' }}>{formik.errors.holdingIdCardPicture}</div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ py: 8, mt: 6 }} display="flex" justifyContent="center">
                      <Button variant="contained" type="submit" fullWidth size="large">
                        Send For Approval
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

RequestForm.propTypes = {
  RequestOpen: PropTypes.bool,
  handleCreatorRequestDialogClose: PropTypes.func,
};

export default RequestForm;
