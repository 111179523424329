import PropTypes from 'prop-types';
import React,{useState,useEffect} from 'react';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ColorMultiPicker } from '../../../components/color-utils';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const FILTER_ROLE_OPTIONS = [
{ value: 'all', label: 'All' },
{ value: 'true', label: 'Creator' },
{ value: 'false', label: 'Subscriber' }
]
export const FILTER_STATUS_OPTIONS = [
  { value: "all", label: 'All' },
  { value: 'true', label: 'Banned' },
{ value: 'false', label: 'Active' }];


export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

ListFilter.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  getAllSubscribers: PropTypes.func,
};


export default function ListFilter({ openFilter, onOpenFilter, onCloseFilter,getAllSubscribers }) {

    const [roleValue, setRoleValue] = useState('all');
    const [statusValue, setStatusValue] = useState('all');
    const [status, setStatus] = useState(null);
    const [role, setRole] = useState(null);

    const handleRoleValChange = (event) => {
        setRoleValue(event.target.value);
      };
    const handleStatusValChange = (event) => {
        setStatusValue(event.target.value);
      };


   const handleStatusChange = (event) => {
      const status=event.target.value;
      setStatus(event.target.value);

    };

    const handleRoleChange = (event) => {
      const Role=event.target.value;
      setRole(event.target.value);
    }

    const handleReset=()=>{
      setRole(null)
      setStatus(null)
      setRoleValue("all")
      setStatusValue("all")
    }

useEffect(()=>{
getAllSubscribers(role,status)
},[role,status])

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Role
              </Typography>
              <FormGroup>
                <RadioGroup
                value={roleValue}
                onChange={handleRoleValChange}
                >
                {FILTER_ROLE_OPTIONS.map((item) => (
                  <FormControlLabel key={item.label} control={<Radio />} value={item.value} onClick={handleRoleChange} label={item.label} />
                ))}
                </RadioGroup>
              </FormGroup>
            </div>

            <div>
              <Typography variant="subtitle1" gutterBottom>
                Status
              </Typography>
              <FormGroup>
              <RadioGroup
                value={statusValue}
                onChange={handleStatusValChange}
                >
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel key={item.label} value={item.value} control={<Radio />} label={item.label}  onClick={handleStatusChange}  />
                ))}
                  </RadioGroup>
              </FormGroup>
            </div>


          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
            onClick={()=>handleReset()}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
