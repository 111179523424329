
import {  useEffect } from 'react';
import PropTypes from 'prop-types';

// @mui
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  TableContainer,
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
} from '@mui/material';

// icons

import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CloseIcon from '@mui/icons-material/Close';

// components
import Scrollbar from '../../../components/scrollbar';

// Apis

export default function PostDetails({ handlePostDialogClose, PostDialogOpen, singlePost }) {
  // Functions of Dialog for the Post Details
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  //   const getSingleReportedPost = async (postId) => {
  //     const AdminToken = localStorage.getItem('admin');
  //     const post = await getSingleReportedPostApi(AdminToken, postId);
  //     setSingleReportedPost(post);
  //   };

  useEffect(() => {}, []);

  return (
    <>
      {/* ================Post Detail Dailog==================== */}
      <div>
        <Dialog
          onClose={handlePostDialogClose}
          aria-labelledby="customized-dialog-title"
          open={PostDialogOpen}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: '#F9FAFB',
              boxShadow: 'none',
            },
          }}
        >
          <BootstrapDialogTitle id="customized-dialog-title" color="primary" onClose={handlePostDialogClose}>
            Post Details
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Box component="div" sx={{ display: 'flex', mt: 4 }}>
              <Box
                component="div"
                sx={{ boxShadow: 3, p: 2, width: '60%', mr: 4, borderRadius: 2, backgroundColor: 'white' }}
              >
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    width: 100,
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    boxShadow: 4,
                    position: 'relative',
                    bottom: 40,
                  }}
                >
                  <PersonIcon sx={{ color: 'white', fontSize: 36 }} />
                </Box>
                <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', color: 'primary.main' }}>
                  User's Post
                </Typography>
                <Box component="div" sx={{ boxShadow: 4, p: 4, borderRadius: 4 }}>
                  <Grid container rowSpacing={2}>
                    <Grid item sx={{ width: '100%', display: 'flex' }}>
                      <Avatar src="" sx={{ width: 60, height: 60 }} />
                      <Grid sx={{ ml: 1, mt: 2 }}>
                        <Typography variant="subtitle2" sx={{ lineHeight: 0.5 }}>
                          {singlePost[0]?.postedBy?.name}
                        </Typography>
                        <Typography variant="caption">{singlePost[0]?.postedBy?.email}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                      {singlePost[0]?.description}
                    </Grid>
                    <Grid item width="100%">
                      <img
                        src={singlePost && singlePost[0]?.postContent}
                        style={{ width: '100%', height: '300px', borderRadius: '8px' }}
                        alt="im"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box component="div" sx={{ boxShadow: 3, p: 2, width: '40%', borderRadius: 2, backgroundColor: 'white' }}>
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    width: 100,
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 2,
                    boxShadow: 4,
                    position: 'relative',
                    bottom: 40,
                  }}
                >
                  <QueryStatsIcon sx={{ color: 'white', fontSize: 36 }} />
                </Box>
                <Typography variant="h5" gutterBottom>
                  Post Details
                </Typography>
                <Box>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Reports:</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost && singlePost[0]?.Reported?.length}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Liked By:</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost && singlePost[0]?.likedBy?.length}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Bookmarked By</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost[0]?.bookmarkedBy?.length}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Featured:</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost && singlePost[0]?.featured ? 'Yes' : 'No'}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Locked:</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost && singlePost[0]?.isLocked ? 'Yes' : 'No'}</Typography>
                    </Grid>
                    <Grid item xs={6} display="flex">
                      <Typography variant="subtitle1">Created At:</Typography>
                      <Typography sx={{ mx: 1 }}>{singlePost && singlePost[0]?.createdAt.substring(0, 10)}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ boxShadow: 4, mt: 5, borderRadius: 4, p: 2 }}>
                    <Grid item sx={{ width: '100%', py: 2 }}>
                      <Typography variant="h5">Reports</Typography>
                    </Grid>
                    <Grid item sx={{ width: '100%', maxHeight: '400px' }}>
                      <Scrollbar>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Message</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {singlePost &&
                                singlePost.length > 0 &&
                                singlePost[0]?.Reported.map((row) => {
                                  const { _id, message } = row;
                                  return (
                                    <TableRow hover key={_id} tabIndex={-1}>
                                      <TableCell component="th" scope="row" padding="none">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                          <Avatar alt={'her'} src="" />
                                          <Typography variant="subtitle2" noWrap>
                                            {row.ReportedBy.name}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell component="th" scope="row" padding="4px">
                                        <Typography variant="subtitle2">{message}</Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                          {singlePost[0]?.Reported.length === 0 ? (
                            <Box sx={{ width: '100%', textAlign: 'center', py: 8 }}>
                              <Typography variant="h5" color="primary">
                                No Report Found!
                              </Typography>
                            </Box>
                          ) : (
                            ''
                          )}
                        </TableContainer>
                      </Scrollbar>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
PostDetails.propTypes = {
  handlePostDialogClose: PropTypes.func,
  PostDialogOpen: PropTypes.bool,
  singlePost: PropTypes.array,
};